html,
body {
  font-family: "Amazon Ember";
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  /* overflow-x: hidden; */
  scroll-behavior: smooth;
  overflow:auto;
}

html {
  scroll-behavior: smooth;
}

/* Scroll Bar */

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(66, 66, 66, 0.2);
  border: 0px;
  background-clip: padding-box;
  border-radius: 5px;
}

nav {
  background-color: #232f3e;
  padding: 1rem 1rem;
}

.navbar {
  padding: 0.1rem 1rem;
}

.dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

#search_btn {
  background-color: #198754;
}

.input-group {
  width: 90%;
  margin-right: 35%;
}

#search_field {
  height: 2.4rem;
  padding: 1rem;
}

#login_btn,
#view_btn,
#new_review_btn {
  background-color: #198754;
  padding: 0.4rem 1.8rem;
  color: white;
}




.custom-dropdown-menu::after {
  border-top-color: green !important; /* Change the color of the dropdown icon */
}

#cart {
  font-size: 1rem;
  color: white;
}

.table-sm td,
.table-sm th {
    font-size: 0.75rem; /* Adjust the font size as needed */
}

.content {
  z-index: 1;
}

.footer {
  margin-top: 10;
  position: relative;

}

/* Define styles for screens with a maximum width of 600px (mobile) */
@media (max-width: px) {
  .responsive-container {
     height: '100%'
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  font-size: 30px;
  text-align: center;
}

.flex-item-left {
  padding: 10px;
  flex: 50%;
}

.small-table {
  font-size: 12px; /* Reduce the font size */
  th, td {
      padding: 4px; /* Reduce padding */
  }
}

.nav-pills{

padding: 7px;

font-size: small;


}
/* Tables.css */
.custom-tabs .nav-tabs .nav-link {
  padding: 10px; /* Reduce padding */
  font-size: 12px; /* Reduce font size */

}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #198754
}

.custom-tabs .nav-tabs {
  flex-wrap: nowrap; /* Prevent tabs from wrapping */
}


.flex-item-right {
  padding: 10px;
  flex: 50%;
}


body {
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

#cart_count {
  background-color: #198754;
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
  color: black;
  font-weight: bold;
  font-size: 0.9rem;
}

/* Home page */
#products_heading {
  margin-top: 1.8rem;
}

.card {
  height: 100%;
}

.card-title a {
  color: #2e2e2e;
  text-decoration: none;
}

.card-title a:hover {
  color: #198754;
  text-decoration: none;
}

.card-body {
  padding-left: 0;
}

.card-text {
  font-size: 1.4rem;
}

.card-img-top {
  width: 200px;
  height: 150px;
}

.ratings {
  font-size: 1.2rem;
  color: #fdcc0d;
}

#no_of_reviews {
  font-size: 0.9rem;
  color: grey;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Footer */

/* Product Details Page */
#product_id {
  color: grey;
  font-size: 0.8rem;
}

#product_price {
  font-size: 2rem;
  font-weight: bold;
}

#cart_btn,
#review_btn {
  border-radius: 2rem;
  background-color: #198754;
  border: none;
  padding: 0.5rem 2rem;
}

#product_seller {
  color: grey;
  font-size: 0.9rem;
}

#stock_status {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 0.3rem;
}

.redColor {
  color: red;
}

.greenColor {
  color: green;
}

#product_image {
  margin-top: 9rem;
}

/* Loader */
.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}
.loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #198754;
  border-color: #198754 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Cart */

.cart-item {
  margin: 1.8rem 0rem;
  padding: 0rem 0.7rem;
}

.cart-item a {
  color: grey;
}

#delete_cart_item {
  color: red;
  background: white;
  padding: 0.3rem 0.5rem;
  font-size: 1.1rem;
  border: none;
}

#card_item_price {
  color: #198754;
  font-weight: bold;
  font-size: 1.4rem;
}

#checkout_btn,
.review-btn {
  background-color: #198754;
  border-color: #198754;
  margin-top: 2rem;
  border-radius: 5rem;
}

#view_order_details {
  background-color: #198754;
  border-color: #198754;
}

#order_summary {
  border: 1px solid #e3e3e3;
  border-radius: 1rem;
  padding: 2rem 1.5rem;
}

.order-summary-values {
  float: right;
  font-weight: bold;
}

.stockCounter input {
  border: none;
  width: 3rem;
  text-align: center;
}

.plus,
.minus {
  padding: 0.1rem 0.5rem;
}

.stockCounter input::-webkit-outer-spin-button,
.stockCounter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: white;
}

/* Login & Register */

.no-margin {
  margin-top: 0rem; /* Or any other margin property you want to remove */
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  font-weight: 700;
}

.wrapper form {
  padding: 2.5rem 3rem;
}

.wrapper form .btn,
.fetch-btn {
  background-color: #198754;
  border-color: #198754;
  color: white;
  margin-top: 1rem;
}

.fetch-btn:hover {
  background-color: #198754;
  color: white;
}

.wrapper form a {
  font-size: 0.9rem;
  color: grey;
}

/* Avatar */
.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 3rem;
  width: 3rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

/* Header User Profile */

.avatar-nav {
  margin-right: 0.6rem;
  height: 2.2rem;
  width: 2.2rem;
}

/* Checkout Steps */

.checkout-progress div {
  box-sizing: border-box;
}

.checkout-progress {
  display: block;
  clear: both;
  margin: 20px auto;
  width: auto;
  font-family: sans-serif;
  overflow: auto;
}

.step {
  margin: 0;
  border: 0;
  letter-spacing: 1px;
  line-height: 30px;
  padding: 5px 10px 5px 15px;
  color: grey;
  text-decoration: none;
  cursor: default;
  font-weight: bold;
  float: left;
  height: auto;
}

.incomplete {
  background: #eeeeee;
}

.active-step {
  background: #198754;
  color: #fff;
}

.triangle-active {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #198754;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-active {
  width: 0;
  float: left;
  border-top: 20px solid #198754;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #198754;
  margin-right: -1px;
}

.triangle-incomplete {
  float: left;
  width: 0;
  border-top: 20px solid transparent;
  border-left: 15px solid #eeeeee;
  border-bottom: 20px solid transparent;
  margin-left: -1px;
}

.triangle2-incomplete {
  width: 0;
  float: left;
  border-top: 20px solid #eeeeee;
  border-left: 15px solid #fff;
  border-bottom: 20px solid #eeeeee;
  margin-right: -1px;
}

/* User Profile */

.avatar-profile {
  height: 16rem;
  width: 16rem;
}

.user-info h4 {
  font-weight: 800;
  color: grey;
  margin-top: 2rem;
}

.user-info p {
  font-size: 1.1rem;
}

#edit_profile {
  background-color: #198754;
  border-color: #198754;
}

/* Confirm Order */

.order-confirm p,
.order-details p {
  margin-left: 1.5rem;
}

.update-btn,
#create_btn {
  background-color: #198754;
  border-color: #198754;
  color: white;
}

.update-btn:hover {
  color: white;
}

/* Pagination */
.page-item.active .page-link {
  background-color: #198754;
  border-color: #198754;
}

.page-link {
  color: #198754;
}

.page-link:hover {
  color: #198754;
}

/* Products List Admin */
#products_table .btn {
  padding: 0.2rem 0.5rem;
  margin-left: 0.4rem;
}

/* Dashboard */

.admin-child-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.card-font-size {
  font-size: 1.5rem;
}

/* Reviews */

.rating {
  margin-top: 10rem;
}

.stars {
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 0;
}

.star {
  display: inline;
  list-style: none;
  font-size: 3rem;
  padding-left: 0.9rem;
  color: #e3e3e3;
}

.star:first-child {
  padding-left: 0;
}

.orange {
  color: #198754;
}

.yellow {
  color: #fdcc0d;
}

.review_user {
  font-size: 0.8rem;
  color: grey;
}

.cross-button {
  background-color: #dc3545;
}

#filter_form .row .col {
  padding-left: 5px;
  padding-right: 5px;
}

#filter_form button {
  background-color: #198754;
  border-color: #198754;
}

.filter {
  margin-top: 5.8rem;
}

/* Sidebar */

.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item {
  border: none;
}

.list-group-item.active {
  background-color: #f3f4f6;
  border-color: #f3f4f6;
  border-radius: 0.3rem;
  color: #198754;
}